
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";

import AbpBase from "../../lib/abpbase";

// // Counter Widgets
import WidgetCounter from "../../components/Muse/Widgets/WidgetCounter.vue";

// // "Projects" table component.
import CardProjectTable from "../../components/Muse/Cards/CardProjectTable.vue";

// // Order History card component.
import CardOrderHistory from "../../components/Muse/Cards/CardOrderHistory.vue";

// // Information card 1.
import CardInfo from "../../components/Muse/Cards/CardInfo.vue";

// // Information card 2.
import CardInfo2 from "../../components/Muse/Cards/CardInfo2.vue";
// Bar chart for "Active Users" card.
import CardBarChart from "../../components/Muse/Cards/CardBarChart.vue";

// // Line chart for "Sales Overview" card.
import CardLineChart from "../../components/Muse/Cards/CardLineChart.vue";

import Campana from "../../store/entities/OZONE/campana";

import Participacion from "../../store/entities/OZONE/participacion";
import PageRequest from "../../store/entities/page-request";
import HomeObject from "../../store/entities/OZONE/home";

const tableColumns = [
  {
    title: "Campaña",
    dataIndex: "company",
    scopedSlots: { customRender: "company" },
    width: 300,
  },
  {
    title: "Cliente",
    dataIndex: "client",
    scopedSlots: { customRender: "client" },
  },
  {
    title: "Presupuesto",
    dataIndex: "budget",
    class: "font-bold text-muted text-sm",
  },
  {
    title: "Participaciones",
    scopedSlots: { customRender: "completion" },
    dataIndex: "completion",
  },
  {
    title: "Total Envios",
    scopedSlots: { customRender: "totalShipments" },
    dataIndex: "totalShipments"
  },
  {
    title: "Total de Transferencias",
    scopedSlots: { customRender: "totalTransfers" },
    dataIndex: "totalTransfers"
  }
];

// "Projects" table list of rows and their properties.

class PageHomeRequest extends PageRequest {}

@Component({
  components: {
    WidgetCounter,
    CardProjectTable,
    CardOrderHistory,
    CardInfo,
    CardInfo2,
    CardBarChart,
    CardLineChart,
  },
})
export default class Home extends AbpBase {
  home: HomeObject = new HomeObject();
  pagerequest: PageHomeRequest = new PageHomeRequest();
  datos: Array<any> = [];
  campanas: Array<any> = [];
  campanasTotales: Array<any> = [];
  campanasActivas: Array<any> = [];
  campanasConstruccion: Array<any> = [];
  participacionesTotales: Array<any> = [];
  $store: any;

  created() {
    this.getParticipacionesHome();
    // this.getpage();
    // this.getTrasferencia();
  }

  get tableColumns() {
    return tableColumns;
  }
  get participacioneshoy() {
    return {
      title: "Participaciones Hoy",
      valuee: this.$store.state.homeModule.pHoy,
      prefix: "",
      icon: `<i class="fa fa-user-check" style="color:white"></i>`,
    };
  }
  get campañasActivas() {
    return {
      title: "Campañas Activas",
      valuee: this.$store.state.homeModule.cAct,
      icon: `<i class="fa fa-comments-dollar" style="color:white"></i>`,
    };
  }
  get campañasenConstruccion() {
    return {
      title: "Campañas En Construcción",
      valuee: this.$store.state.homeModule.cCons,
      prefix: "",
      status: "danger",
      icon: `<i class="fa fa-user-tie" style="color:white"></i>`,
    };
  }
  get campañasTotales() {
    return {
      title: "Campañas En Total",
      valuee: this.$store.state.homeModule.cTot,
      prefix: "",
      icon: `<i class="fa fa-truck" style="color:white"></i>`,
    };
  }

  //get 

  async getpage() {
    this.pagerequest.maxResultCount = 5;
    await this.$store.dispatch({
      type: "homeModule/getHome",
      data: this.pagerequest,
    });

    this.campanasTotales = this.$store.state.homeModule.list;

    // for (var campanas in this.campanasTotales) {
    //   if (this.campanasTotales[campanas].estadoCampanaId == 1) {
    //     this.campanasActivas.push(campanas);
    //   }
    //   if (this.campanasTotales[campanas].estadoCampanaId == 3) {
    //     this.campanasConstruccion.push(campanas);
    //   }
    // }


  }

  get datostabla() {
    for (var datos in this.campanasTotales) {
      var campana: HomeObject = new HomeObject();

      campana.key = datos;
      campana.company = { name: this.campanasTotales[datos].nombre };
      campana.client = this.campanasTotales[datos].cliente.nombre;
      campana.budget = this.campanasTotales[datos].presupuesto;
      campana.completion = this.campanasTotales[datos].participacionesporCampaña;
      campana.totalShipments = this.campanasTotales[datos].enviosPorCampaña;
      campana.totalTransfers = this.campanasTotales[datos].totalTransferencias;
      campana.campanaTipoId = this.campanasTotales[datos].tipo;
      this.datos.push(campana);
    }

    return this.datos;
  }
  async getParticipacionesHome() {
    await this.$store.dispatch({
      type: "homeModule/getParticipacionesHome",
      data: this.pagerequest,
    });

    this.participacionesTotales = this.$store.state.homeModule.pData;
    this.campanasTotales = this.$store.state.homeModule.list;
  }

  async getTrasferencia() {
    await this.$store.dispatch({
      type: "transferencia/getAll",
      data: this.pagerequest,
    });
  }
}
